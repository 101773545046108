@import "./variables.scss";

.App-header-image {
  width: 100% \9;
  max-width: 21rem;
}

header {
  text-align: center;

  & h1 {
    color: #114260;
  }
}

button.btn.btn-primary {
  background-color: $button-primary;
  border-color: $button-primary;
}

.card-body h2.card-title {
  color: $brand-color-prussian !important;
  font-size: 1.7rem !important;
}
