/* Colors */
$brand-color-teal: rgb(7, 79, 104);
$brand-color-teal-very-light: #dbf6ff;
$brand-color-prussian: rgb(0, 49, 79);
$brand-color-prussian-light: rgb(0, 105, 171);
$brand-color-gray: #bbb;
$brand-color-light-gray: #eee;
$brand-color-accent: #bababa;
$brand-color-citrus: #B5A804;
$brand-color-citrus-dark: darken($brand-color-citrus, 17%);
$brand-color-citrus-light: lighten($brand-color-citrus, 55%);
$brand-logo: url("/static/assets/logo.svg");
$error-red: #b5453c;
$error-red-light: #f5dcda;
$button-primary: #007fcc;
