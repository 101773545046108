.complete-mailing-address {
  height: 7rem;
}

.agree-with-statement-of-faith, .agree-with-ethos-statement {
  height: 17rem;
}

.submit-button-area {
  float: right;
}
