@import "../../variables.scss";

.errors {
  & input.form-control,
  & input.form-control::placeholder,
  & textarea.form-control,
  & textarea.form-control::placeholder,
  & select.form-control {
    border-color: $error-red;
    background-color: $error-red-light !important;
    color: $error-red;
  }
  color: $error-red;

  .validation-error {
    background-color: $error-red;
    color: white;
    padding: 0.5rem;
    padding-left: 0.8rem;
  }
}

.required .label-text::after {
  content: " *";
  color: $error-red;
}

.form-control{
  border-radius: 0;
}

div.intl-tel-input {
  display: block;
}
