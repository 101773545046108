@import "../../variables.scss";

.navbar {
  background-color: $brand-color-teal-very-light;

  &.navbar-light .navbar-brand {
      color: $brand-color-prussian;
  }

  .logo {
    margin: 10px;
  }
}

@media (min-width: 767.99px) {
  .navbar {
    .logo {
      width: 100% \9;
      max-width: 4rem;
      margin-left: 3rem;
      margin-right: 1.8rem;
    }
  }
}

// Only small devices
@media (max-width: 767.98px) {
  .navbar.navbar-light {
    .navbar-brand {
      width: 100%;
      text-align: center;
      margin: 0;
      margin-top: 1rem;
    }
    .logo {
      margin: auto;
      display: block;

      width: 100% \9;
      max-width: 4rem;
    }
  }
}
