@import "../../variables.scss";

Footer {
  background-color: $brand-color-teal-very-light;

  & h2 {
    color: $brand-color-prussian;
  }
}

.postfooter {
  height: auto;
  background-color: $brand-color-prussian;
  color: white;
  padding-bottom: 2rem !important;

  a:active, a:hover, a:link, a:visited {
    color: white;
  }
}
